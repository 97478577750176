body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.main-container {
  width:80%;
  margin-left: auto;
  margin-right: auto;
}

.calculator {
  display: flex;
  flex-direction:column;
  width: 380px;
}

.screen-container {
  text-align: end;
  background-color: #858694;
  color: #FFFFFF;
  padding: 5px;
}

.orange {
  background-color: orange;
}

.gray-color {
  background-color: #E0E0E0;
}

.touch-pad {
  display: grid;
  padding-top:0.1px;
  grid-template-columns: repeat(4,95px);
  grid-template-rows: minmax(30px,auto) repeat(3,80px);
}

button {
  border-color: #858694;
} 

.zero-btn {
  width: 190px;
  height: 65px;
}

.dot-btn {
  width: 95px;
  height: 65px;
}

.equal-btn {
  width: 95px;
  height: 65px;
}
